@charset "utf-8";

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Montserrat;
  src:
    url("../fonts/Montserrat-VF_wght.ttf") format("woff2 supports variations"),
    url("../fonts/Montserrat-VF_wght.ttf") format("woff2-variations");
  font-stretch: 25% 150%;
  font-style: oblique 0deg 10deg;
  font-weight: 100 900;
  font-display: swap;
}
